<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="更新时间">
                        {{ form.update_time }}
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-radio v-model="form.state" :label="1">开启</el-radio>
                        <el-radio v-model="form.state" :label="0">关闭</el-radio>
                    </el-form-item>
                    <el-form-item label="封面">
                        <Qnupload v-model="form.cover" :sum="1"/>
                        <div class="form-tip">图片尺寸646X607</div>
                    </el-form-item>
                    <el-form-item label="活动券">
                        <el-select
                                v-model="form.coupon_activity_uuid"
                                filterable
                                placeholder="请选择优惠券活动"
                                remote
                                :remote-method="get_activity_list"
                                :loading="select_loading"
                                style="width: 100%">
                            <el-option
                                    v-for="item in activity_list"
                                    :key="item.coupon_activity_uuid"
                                    :label="item.coupon_activity_title"
                                    :value="item.coupon_activity_uuid">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规则">
                        <el-input v-model="form.rule" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="1000" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button v-if="is_auth('coupon.newuser.setsave')"
                                   size="medium" type="primary" @click="save">保存
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: false,
            select_loading: false,
            activity_list: [],                   // 优惠活动列表

            form: {
                title: '',                      // 名称
                cover: '',                      // 封面
                coupon_activity_uuid: '',       // 选择的优惠券活动
                state: 0,                       // 状态
                receive_sum: '',                // 已参与人数
                update_time: '',                // 更新时间
                rule: '',                       // 规则
            },
        }
    },
    // 创建
    created() {
        this.get_activity_list(null)          // 获取可用优惠券列表
        this.get_setinfo()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取优惠活动列表
        get_activity_list(key) {
            if (key === '') return false;
            let postdata = {
                api_name: 'coupon.newuser.getusableactivity',
                token: this.Tool.get_l_cache('token'),
                key,
            }
            this.select_loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.select_loading = false
                if (json.code === 0) {
                    this.activity_list = json.data
                }
            })
        },
        // 获取新人活动设置
        get_setinfo() {
            let postdata = {
                api_name: 'coupon.newuser.setinfo',
                token: this.Tool.get_l_cache('token'),
            }
            this.select_loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.select_loading = false
                if (json.code === 0) {
                    this.form = json.data
                }
            })
        },
        //保存
        save() {
            let postdata = {
                api_name: "coupon.newuser.setsave",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.get_setinfo()
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })

        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appoint {
    border-left: 10px solid #409EFF;
}
</style>
